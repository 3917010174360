import React, { useState, useEffect } from "react";
import Layout from "./../../components/layout"
import SEO from "./../../components/seo"
import { OutboundLink } from "gatsby-plugin-google-gtag"

// Import CSS
import "./../../styles/free-tools.css"
import "./../../styles/products.css"

// Import components
import GetStartedBanner from "./../../components/get-started-footer"

const TLSChecker = ({ data }) => (
  <Layout>
    <SEO
      title="TLS Checker - Odown Free Tools"
      description="Is your SSL certificate installed correctly? Use this free SSL checker tool to verify and troubleshoot any problems, ensuring secure connections."
      pathname={`/free-tools/tls-checker/`}
      image={{
        src: "/general-image-og--tls_checker.jpg",
        width: 1600,
        height: 800,
      }}
    
    />
    {/* HEADER */}
    <HeadingSection />
    <TLSCheckerSection />
    {/* About TLS Checker Section*/}
    <AboutTLSChecker />
    {/* Why Does It Matter Section*/}
    <WhyDoesItMatter />
    {/* Get started banner */}
    <GetStartedBanner />
  </Layout>
)

/**
 * Heading Section
 */
const HeadingSection = ({ data }) => {
  return (
    <div className="u-features freetools-hero">
      <div className="container">
        <h1 className="u-features__heading-title">
        Free TLS Security Checker Tool - Test Your Server Now
        </h1>
        <div className="FreeTool-heading__description">
        Test your website's HTTPS security strength now. This Odown tool analyzes TLS versions and encryption to help protect your site. Try it free.        </div>
      </div>
      <div className="FreeTool-heading__button">
        <OutboundLink
          className="btn-primary"
          eventCategory="Launch the Odown App"
          eventAction="Click"
          eventLabel="Goto signup"
          href="https://app.odown.io/signup"
        >
          Trial Odown free for 14 days
        </OutboundLink>
      </div>
    </div>
  )
}

/** * TLS Checker Section */
const TLSCheckerSection = () => {
  const [domain, setDomain] = useState('');
  const [region, setRegion] = useState('');
  const [result, setResult] = useState(null);
  const [loading, setLoading] = useState(false);
  const [error, setError] = useState(null);
  const [disableButton, setDisableButton] = useState(false);
  const [countdown, setCountdown] = useState(0);

  const validateUrl = (url) => {
    if (url.startsWith("http://") || url.startsWith("https://")) {
      return url;
    }
    return `https://${url}`;
  };

  const formattedUrl = validateUrl(domain); 

  const handleInputChange = (e) => {
    setDomain(e.target.value);
  };

  const handleRegionChange = (e) => {
    setRegion(e.target.value);
  };

  const handleTestClick = async () => {

    setResult(null);

    if (!domain) {
      setError('Please enter a domain.');
      return;
    }
  
    if (!region) {
      setError('Please select a region.');
      return;
    }

    setLoading(true);
    setDisableButton(true);
    setError(null);
    setCountdown(5);

    const apiUrl = `https://odown-tls-checker.regionsping.workers.dev?url=${formattedUrl}&region=${region}`;
  
    try {
      const response = await fetch(apiUrl);
      if (!response.ok) {
        throw new Error(`Server returned an error: ${response.status}`);
      }

      const tlsdata = await response.json();

      setResult(tlsdata.supportedProtocols);
        }

    catch (error) 
    {
      setError(`Error: ${error.message}`);
      setResult(null);
    } 
    finally 
    {
    setLoading(false);
    }
  };

  useEffect(() => {
    if (countdown > 0) {
      const timer = setTimeout(() => setCountdown(countdown - 1), 1000);
      return () => clearTimeout(timer);
    }
    if (countdown === 0 && disableButton) {
      setDisableButton(false);
    }
  }, [countdown, disableButton]);

  return (
    <section id="tlsTestTool" className="tls-test-tool">
      <div className="tls-container">
        <div className="tls-section">
          <span className="tls-label">Check the TLS setup</span>
          <input
            type="text"
            value={domain}
            onChange={handleInputChange}
            placeholder="https://www.example.com/"
            className="tls-input"
          />
        </div>
        <div className="tls-section">
          <span className="tls-label">Test Location</span>
          <select
            className="tls-location-select"
            value={region}
            onChange={handleRegionChange}
          >
            <option value="">Select a region</option>
            <option value="Amsterdam_NL">Amsterdam - NL</option>
            <option value="Dallas_US">Dallas - US</option>
            <option value="Frankfurt_DE">Frankfurt - DE</option>
            <option value="Fremont_CA_US">Fremont-CA - US</option>
            <option value="London_UK">London - UK</option>
            <option value="Mumbai_IN">Mumbai - IN</option>
            <option value="NewYork_US">NewYork - US</option>
            <option value="Paris_FR">Paris - FR</option>
            <option value="SaoPaulo_BR">SaoPaulo - BR</option>
            <option value="Singapore_SG">Singapore - SG</option>
            <option value="Sydney_AUS">Sydney - AUS</option>
            <option value="Vancouver_CA">Vancouver - CA</option>
          </select>
        </div>
        <div className="tls-button-section">
          <button
            className={`tls-test-button ${disableButton ? 'button-disabled' : ''}`}
            onClick={handleTestClick}
            disabled={disableButton}
          >
            {countdown > 0 ? `${countdown}s` : 'Test'}
          </button>
        </div>
      </div>
      {result && (
        <div className="tls-card">
          <h3 className="tls-label">Supported Protocols</h3>
          <div className="tls-flex-container">
            {Object.entries(result).map(([protocol, status]) => (
              <div key={protocol} className="tls-flex-item">
                <span className="tls-protocol">{protocol}</span>
                <span className={status === 'Supported' ? 'tls-supported' : 'tls-not-supported'}>
                {status === 'Supported' ? '✔️' : '❌'} {status}
                </span>              
              </div>
            ))}
          </div>
        </div>
      )}
      {error && <div className="tls-error-section">{error}</div>}   
    </section>
  );
};

  const AboutTLSChecker = () => {
    return (
      <section className="About-TLSChecker-section">
        <div className="container">
          <div className="u-main-feature__wrapper">
            <div className="u-main-feature__text-content">
              <p className="content-desc2">
              Transport Layer Security (TLS) is a protocol that secures communication between two computers.
              </p>
              <p className="content-desc2">
              In simpler terms, TLS is what powers HTTPS on your website. Like any standard, HTTPS continues to evolve with advancements in encryption technology, leading to the release of newer TLS versions.              </p>
              <p className="content-desc2">
              That’s where this TLS testing tool comes in—it checks which TLS versions your website supports and identifies the encryption algorithms in use.              </p>
            </div>
          </div>
        </div>
      </section>
    );
  };
  
const WhyDoesItMatter = () => {
    return (
        <section className="WhyDoesItMatter-section">
          <div className="container">
            <div className="u-main-feature__wrapper">
              <div className="u-main-feature__text-content">
                <h2 className="content-title1">
                Why Does It Matter?
                </h2>
                <p className="content-desc2">
                Enabling HTTPS on your website is only the first step. Cybersecurity is a constantly changing landscape. Technologies once considered secure can become obsolete over time. This TLS test evaluates the strength of your HTTPS security. If your website doesn’t support the latest TLS version, it’s crucial to act. </p>
              </div>
            </div>
          </div>
        </section>
      );
};
export default TLSChecker
